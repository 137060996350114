const Home = () => import('@/pages/home/index')
/* const Product = () => import('@/pages/product/product') */
const Case = () => import('@/pages/case/case.vue')
const About = () => import('@/pages/about/index')

import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  /* {
    path: '/Product/:id',
    name: 'Product',
    component: Product
  }, */
  {
    path: '/case/:id',
    name: 'Case',
    component: Case
  },
  {
    path: '/About',
    name: 'About',
    component: About
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

/* 页面跳转时，重置滚动条位置 */
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  next()
})

export default router
