<template>
  <el-affix>
    <el-menu class="el-menu-demo" mode="horizontal" :ellipsis="false">
      <el-menu-item index="0" id="logo_box"><img id="logo_img" src="./assets/logo2.png" /></el-menu-item>
      <div class="flex-grow" />
      <el-menu-item index="1">
        <router-link to="/" @click.prevent="toHome">首页</router-link>
      </el-menu-item>
      <!-- <el-sub-menu index="2">
        <template #title>产品中心</template>
        <el-menu-item index="2-1"><router-link :to="{ name: 'Product', params: { id: 1 } }"> ERP</router-link></el-menu-item>
        <el-menu-item index="2-2"><router-link :to="{ name: 'Product', params: { id: 2 } }"> MES</router-link></el-menu-item>
        <el-menu-item index="2-3"><router-link :to="{ name: 'Product', params: { id: 3 } }"> 其他产品</router-link></el-menu-item>
      </el-sub-menu> -->
      <el-sub-menu index="2">
        <template #title>成功案例</template>
        <el-menu-item index="2-1"><router-link :to="{ name: 'Case', params: { id: 1 } }">模组行业</router-link></el-menu-item>
        <el-menu-item index="2-2"><router-link :to="{ name: 'Case', params: { id: 2 } }">背光行业</router-link></el-menu-item>
        <el-menu-item index="2-3"><router-link :to="{ name: 'Case', params: { id: 3 } }">切割行业</router-link></el-menu-item>
      </el-sub-menu>
      <el-menu-item index="3">
        <router-link to="/About">关于我们</router-link>
      </el-menu-item>
      <el-menu-item index="4">
        <div class="call-num">13622318732</div>
      </el-menu-item>
    </el-menu>
  </el-affix>
  <router-view></router-view>
  <!-- 底部 -->
  <div class="footer_box">
    <div class="footer_box_center">
      <div class="footer_box_info">
        <span>
          <el-icon :size="24"><Phone /></el-icon>
          <p>联系我们</p>
          <p class="phone_number">&nbsp;&nbsp;&nbsp;&nbsp;136-2231-8732</p>
        </span>
        <span>
          <p>
            <el-icon :size="24"><Bell /></el-icon> 服务QQ
          </p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18156844</p>
        </span>
        <span>
          <p>
            <el-icon :size="24"><ChatDotRound /></el-icon> 微信
          </p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;coglnn</p>
        </span>
        <span>
          <p>
            <el-icon :size="24"><Message /></el-icon> 邮箱
          </p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xzliunian@163.com</p>
        </span>
        <span>
          <p>
            <el-icon :size="24"><Location /></el-icon> 公司地址
          </p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深圳市龙岗区新木半里大厦C栋607</p>
        </span>
      </div>
      <div class="footer_box_nav">
        <!-- <div>
          <a href="javascript:void(0);" @click="jumpPage('/Product/1')">产品中心</a>
          <a href="javascript:void(0);" @click="jumpPage('/Product/1')">ERP</a>
          <a href="javascript:void(0);" @click="jumpPage('/Product/2')">MES</a>
          <a href="javascript:void(0);" @click="jumpPage('/Product/3')">其他产品</a>
        </div> -->
        <div>
          <a href="javascript:void(0);" @click="jumpPage('/case/1')">成功案例</a>
          <a href="javascript:void(0);" @click="jumpPage('/case/1')">模组行业</a>
          <a href="javascript:void(0);" @click="jumpPage('/case/2')">背光行业</a>
          <a href="javascript:void(0);" @click="jumpPage('/case/3')">切割行业</a>
        </div>
        <div>
          <a href="javascript:void(0);" @click="jumpPage('/About')">关于我们</a>
        </div>
      </div>
      <div class="footer_box_img">
        <div class="footer_box_logo">
          <img src="@/assets/logo2.png" />
        </div>
        <div class="footer_box_code">
          <img src="@/assets/code.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>

  <!-- 以下是旧代码 -->
  <!-- <div class="footer_box">
    新增 class
    <div class="footer_box_img">
      <img class="footer_img" src="@/assets/logo.png" />
    </div>
    <div class="footer_box_info">
      <p>电话：13622318732</p>
      <p>服务QQ：18156844</p>
      <p>微信：coglnn</p>
      <p>邮箱：xzliunian@163.com</p>
      <p>公司地址：深圳市龙岗区新木半里大厦C栋607</p>
    </div>
    <div class="footer_box_info">
      <div class="footer_code">
        <img src="./assets/code.jpg" alt="" />
      </div>
    </div>
  </div> -->

  <!-- 以下是源代码 -->
  <!-- <el-row class="footer_box">
    <el-col :span="6">
      新增 class
      <div class="footer_box_img" style="text-align: center">
        <img class="footer_img" src="@/assets/logo.png" />
      </div>
    </el-col>
    <el-col :offset="1" :span="11">
      <div class="footer_box_info">
        <p>电话：13622318732</p>
        <p>服务QQ：18156844</p>
        <p>微信：coglnn</p>
        <p>邮箱：xzliunian@163.com</p>
        <p>公司地址：深圳市龙岗区新木半里大厦C栋607</p>
      </div>
    </el-col>
    <el-col :offset="1" :span="4">
      <div class="footer_box_info">
        <div class="footer_code">
          <img src="./assets/code.jpg" alt="" />
        </div>
      </div>
    </el-col>
  </el-row> -->
  <div class="copyright">版权所有 @智易达软件科技有限公司 <a target="_blank" style="color: #fff" href="https://beian.miit.gov.cn">粤ICP备2022113343号</a></div>
</template>

<script>
import router from './router/index.js'
import { Phone, Location, ChatDotRound, Message, Bell } from '@element-plus/icons-vue'
export default {
  name: 'App',
  setup() {
    // 点击导航栏“首页”项时刷新页面
    function toHome() {
      // window.sessionStorage.setItem('entryAnimation', 'false')
      router.afterEach((to, from, next) => {
        location.reload()
        next()
      })
    }

    // 跳转页面方法
    function jumpPage(path) {
      router.push({ path: path })
    }
    return {
      toHome,
      jumpPage
    }
  },
  components: {
    Phone,
    Location,
    ChatDotRound,
    Message,
    Bell
  }
}
</script>

<style>
/* 手机 */
@media screen and (min-width: 320px) and (max-width: 480px) {
  #logo_box {
    display: none;
  }

  .copyright {
    text-align: center;
    line-height: 40px;
    background: #000;
    color: #fff;
    font-size: 12px;
  }
}

/* 平板 */
@media screen and (min-width: 321px) and (max-width: 1024px) {
  #logo_img {
    height: 58px;
  }
}

/* PC客户端或大屏幕设备: 1028px 至更大 */
@media only screen and (min-width: 1029px) {
  #logo_img {
    height: 58px;
  }

  /* 修改 */
  .copyright {
    background: #1ea1bf;
    color: #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    line-height: 60px;
    letter-spacing: 4px;
  }
}

body {
  margin: 0px;
  padding: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.flex-grow {
  flex-grow: 1;
}

/* zzz */
html,
body,
#app {
  height: 100%;
}
</style>
